// src/components/Dashboard.js
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useKeycloak } from "@react-keycloak/web";
import {
  Container,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Checkbox,
  Button,
  IconButton
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import FilterListIcon from '@mui/icons-material/FilterList';
import { styled } from '@mui/material/styles';
import Navbar from './Navbar';
import NavHote from '../../Host/NavHote';
const PREFIX = 'Dashboard';

const classes = {
  container: `${PREFIX}-container`,
  title: `${PREFIX}-title`,
  filterButton: `${PREFIX}-filterButton`,
  activeButton: `${PREFIX}-activeButton`,
  tableHead: `${PREFIX}-tableHead`,
  tableCell: `${PREFIX}-tableCell`,
  statusIcon: `${PREFIX}-statusIcon`,
  inactiveIcon: `${PREFIX}-inactiveIcon`,
  iconButton: `${PREFIX}-iconButton`,
  approveButton: `${PREFIX}-approveButton`,
  rejectButton: `${PREFIX}-rejectButton`
};

const Root = styled('div')(({ theme }) => ({
  [`& .${classes.container}`]: {
    marginTop: theme.spacing(3),
  },
  [`& .${classes.title}`]: {
    marginBottom: theme.spacing(3),
  },
  [`& .${classes.filterButton}`]: {
    marginRight: theme.spacing(1),
  },
  [`& .${classes.activeButton}`]: {
    marginRight: theme.spacing(1),
    backgroundColor: '#fdd835',
    color: 'white'
  },
  [`& .${classes.tableHead}`]: {
    backgroundColor: '#f5f5f5'
  },
  [`& .${classes.tableCell}`]: {
    fontWeight: 'bold'
  },
  [`& .${classes.statusIcon}`]: {
    color: '#4caf50'
  },
  [`& .${classes.inactiveIcon}`]: {
    color: '#f44336'
  },
  [`& .${classes.iconButton}`]: {
    padding: '6px'
  },
  [`& .${classes.approveButton}`]: {
    color: '#4caf50',
    marginRight: theme.spacing(1)
  },
  [`& .${classes.rejectButton}`]: {
    color: '#f44336'
  }
}));

const Dashboard = () => {
  const [reservations, setReservations] = useState([]);
  const { keycloak } = useKeycloak();
  useEffect(() => {
    const fetchReservations = async () => {
      
      const res= await axios.get(process.env.REACT_APP_API_SER+"host/profile/getReservations", {
          headers: {
            Authorization: `Bearer ${keycloak.token}`,
          },
        });
        console.log(res.data);
        setReservations(res.data);
    };

    fetchReservations();
  }, []);

  
  function updateReservationStatus(id, approveStatus) {
    axios.post(
      process.env.REACT_APP_API_SER+`host/profile/updateReservation`, 
      { approveStatus, id },
      {
        headers: {
          Authorization: `Bearer ${keycloak.token}`,
        }
      }
    )
    .then(response => {
      console.log(`Reservation status updated to ${approveStatus}:`, response.data);
      const updatedReservations = reservations.map(reservation => {
        if (reservation.id === id) {
          return { 
            ...reservation, 
            status: approveStatus === "approve" ? "APPROVED" : "REJECTED" 
          };
        }
        return reservation;
      });
      setReservations(updatedReservations);
    })
    .catch(error => {
      console.error('Error updating reservation status:', error);
    });
  }

  return (
    <Root>
      <NavHote />
      <Container className={classes.container} style={{marginTop:"200px"}}>
        <Typography variant="h4" className={classes.title}>Reservations</Typography>
        <div>
          <IconButton className={classes.iconButton}>
            <FilterListIcon />
          </IconButton>
          <Button sx={{ float: "right", backgroundColor: "#E1BD3E" }} className={classes.activeButton}>
            Réservations inactives
          </Button>
          <Button variant="outlined" sx={{ float: "right", marginRight: "20px", color: "#D7A022", borderColor: "#D7A022" }}>
            Réservations actives
          </Button>
        </div>
        <TableContainer component={Paper} className="mt-4">
          <Table>
            <TableHead className={classes.tableHead}>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox />
                </TableCell>
                <TableCell className={classes.tableCell}>#</TableCell>
                <TableCell className={classes.tableCell}>Client</TableCell>
                <TableCell className={classes.tableCell}>Annonce</TableCell>
                <TableCell className={classes.tableCell}>Nombre d'invités</TableCell>
                <TableCell className={classes.tableCell}>Date</TableCell>
                <TableCell className={classes.tableCell}>Montant</TableCell>
                <TableCell className={classes.tableCell}>Status</TableCell>
                <TableCell className={classes.tableCell}>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {reservations.map((reservation, index) => (
                <TableRow key={reservation.id}>
                  <TableCell padding="checkbox">
                    <Checkbox />
                  </TableCell>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>{reservation.user.nom}</TableCell>
                  <TableCell>{reservation.experienceCulinaire.titre}</TableCell>
                  <TableCell>{reservation.nombreInvited}</TableCell>
                  <TableCell>{new Date(reservation.reservationDate).toLocaleDateString()}</TableCell>
                  <TableCell>{reservation.prixTotal} MAD</TableCell>
                  <TableCell>
                    {reservation.status === true ? (
                      <CheckCircleIcon className={classes.statusIcon} />
                    ) : reservation.status === false ? (
                      <CancelIcon className={classes.inactiveIcon} />
                    ) : (
                      'Status'
                    )}
                  </TableCell>
                  <TableCell>
                    <IconButton
                      className={classes.approveButton}
                      onClick={() => updateReservationStatus(reservation.id, "aprove")}
                    >
                      <CheckCircleIcon />
                    </IconButton>
                    <IconButton
                      className={classes.rejectButton}
                      onClick={() => updateReservationStatus(reservation.id, "reject")}
                    >
                      <CancelIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
    </Root>
  );
};

export default Dashboard;
