import React, { useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  MenuItem,
  Select,
  InputLabel,
} from "@mui/material";
import "./Popup.css";
import { Box } from "@mui/system";

const ReservationPopup = () => {
  const [showDialog, setShowDialog] = useState(true); // Dialog est affiché dès le début

  const handleClose = () => {
    setShowDialog(false);
  };

  return (
    <Box className="reservation-popover">
      <Box className="popup-content">
        <img
          src="https://s3-alpha-sig.figma.com/img/b06b/8a76/5a6e96b3d75d91bd83ea342d66b435a0?Expires=1725840000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=iJ5FGKnUaciv4e659veaRAlQP8BPyCrtrGqnbpdAq5JpR5ApT~Pa0A2Vl-02OBbQqJ1SjIFkZjQFU80N~~k7Oez29nUVA69XIywXZ764LCQyF5Rbqa~w6yeCNILjE70dsxHmwq3QkQzs3n6uNBuxdjENTVldjSqnu7jTv11~iOGP6qALwl8qif-JdrmK9j0avHOiqzaG2~jzjtrbd~knvIsdHMtJvbsMLRaHyjRqK86bKgieUDsLZWEClKW1VkJEo3TgGMT3oEX1mXeOyHQS3C64Y54gpWZiEoIc~hcPDpNqATDzaG33lP0JgA0xkv~-mP5XN08bzF4Fp7AW-6eM~Q__"
          alt="Tajine"
          height="100px"
          className="tajine-icon"
        />
        <br />
        <span
          style={{
            fontFamily: "Poppins, sans-serif",
            fontSize: "20.43px",
            fontWeight: 400,
            lineHeight: "32.14px",
            textAlign: "center",
            marginBottom: "12px",
          }}
        >
          Confirmer votre réservation
        </span>

        <div className="reservation-details">
          <div className="detail-row">
            <InputLabel>Nombre d'invités</InputLabel>
            <Select
              value="2"
              onChange={(e) => console.log(e.target.value)} // Ajoute ici une fonction pour gérer le changement de valeur
              className="MuiSelect-root"
            >
              <MenuItem value="2">2 invités</MenuItem>
              <MenuItem value="3">3 invités</MenuItem>
              <MenuItem value="4">4 invités</MenuItem>
            </Select>
          </div>

          <div className="detail-row">
            <InputLabel>Date de réservation</InputLabel>
            <Select
              value="2024-05-13"
              onChange={(e) => console.log(e.target.value)} // Ajoute ici une fonction pour gérer le changement de valeur
              className="MuiSelect-root"
            >
              <MenuItem value="2024-05-13">Lundi 13/05/2024 - 13h00</MenuItem>
              {/* Ajoute d'autres options si nécessaire */}
            </Select>
          </div>
        </div>

        <p className="cancellation-policy">
          Annulation sans frais jusqu'à 1 jour avant la date de réservation.
        </p>

        <div className="total-container">
          <div className="total">Total :</div>
          <div className="price">600 MAD</div>
        </div>

        <Button
          onClick={handleClose}
          variant="contained"
          color="primary"
          className="confirm-button"
          sx={{
            backgroundColor: "#e3b13e",
            color: "white",
            fontWeight: 600,
            fontSize: "18px",
            textTransform: "none",
            marginTop: "20px",
            height: "50px",
            width: "100%",
            "&:hover": {
              backgroundColor: "#d7a022", // Couleur de fond pour l'état hover
            },
          }}
        >
          Je confirme ma réservation
        </Button>
      </Box>
    </Box>
  );
};

export default ReservationPopup;
