import React from "react";

const MenuSection = ({ fields }) => {
  return (
    <div
      style={{
        marginLeft: "120px",
        marginBottom: "30px",
        width: "83%",
        height: "800px",
        backgroundImage: `url('/imgs/designMenu.png')`,
        border: "1px solid #DBA976",
        backgroundSize: "cover",
        backgroundPosition: "center",
        borderRadius: "20px",
        position: "relative",
        color: "black",
        padding: "60px",
        fontSize: "12px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        marginTop: "30px",
        overflow: "hidden", // Assure que rien ne dépasse

        // Styles responsive
        "@media (max-width: 1200px)": {
          marginLeft: "60px",
          width: "90%",
          height: "600px",
          padding: "40px",
          fontSize: "10px",
        },
        "@media (max-width: 768px)": {
          marginLeft: "-20px",
          width: "95%",
          height: "500px",
          padding: "20px",
          fontSize: "8px",
        },
        "@media (max-width: 480px)": {
          marginLeft: "-20px",
          width: "100%",
          height: "400px",
          padding: "10px",
          fontSize: "6px",
        },
      }}
    >
      <span
        style={{
          position: "absolute",
          top: "20px",
          left: "50%",
          transform: "translateX(-50%)",
          fontFamily: "Poppins",
          fontSize: "18px",
          fontWeight: 600,
          lineHeight: "21.64px",
          textAlign: "center",
          color: "black",
        }}
      >
        Menu
      </span>

      <div
        style={{
          marginTop: "60px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
        }}
      >
        {Object.entries(fields).map(([category, items]) => (
          <div key={category}>
            {items.map((item, index) => (
              <div key={index} style={{ marginBottom: "30px" }}>
                <p
                  style={{
                    fontWeight: "bold",
                    margin: "0",
                    color: "#B88454",
                    fontSize: "18px",
                    textAlign: "center",
                  }}
                >
                  {item.title}
                </p>
                <p
                  style={{
                    margin: "0",
                    fontStyle: "italic",
                    fontSize: "16px",
                    textAlign: "center",
                  }}
                >
                  {item.description}
                </p>
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default MenuSection;
