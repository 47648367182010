import React from "react";
import {
  Card,
  CardMedia,
  CardContent,
  Typography,
  Box,
  Button,
  Avatar,
  Divider,
  Grid,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

const TajineCard = ({ e }) => {
  const navigate = useNavigate();

  // Check if `e` is not null or undefined
  if (!e) {
    return <div>Loading...</div>; // Handle loading state or redirection if `e` is not provided
  }

  // Example images from the `e` object
  const images = e.photos || [];
  return (
    <Box
      sx={{
        top: 50,
        maxWidth: 1200,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column", // Stack items vertically
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          flexDirection: "column",
        }}
      >
        <Card
          sx={{
            borderRadius: 3,
            overflow: "hidden",
            boxShadow: "none",
            display: "flex",
            ml: 2,
            position: "relative", // Position button correctly
            flexDirection: { xs: "column", sm: "row" }, // Stack images vertically on small screens
          }}
        >
          <Box
            sx={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            <CardMedia
              component="img"
              image={`${process.env.REACT_APP_API_SER}photos/${images[0]?.id}`}
              alt="Tajine"
              sx={{
                borderRadius: 3,
                width: { xs: "100%", sm: 560 }, // Full width on small screens
                maxWidth: { xs: "100%", sm: 541 },
                height: { xs: 300, sm: 471 }, // Adjust height on small screens
                objectFit: "cover",
              }}
            />
            <Button
              variant="contained"
              size="small"
              sx={{
                position: "absolute",
                bottom: 10,
                left: 10,
                backgroundColor: "white",
                color: "black",
                textTransform: "none",
                fontFamily: "Inter",
                padding: "5px 10px",
                fontWeight: "600",
                boxShadow: 2,
                "&:hover": { backgroundColor: "white" },
              }}
            >
              Voir toutes les photos
            </Button>
          </Box>

          <Box
            sx={{
              display: { xs: "none", sm: "flex" }, // Hide on small screens
              justifyContent: "flex-end",
              pr: 2,
              marginLeft: 2,
              zIndex: 1,
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                maxHeight: 471,
                overflow: "hidden",
              }}
            >
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: "repeat(2, 1fr)",
                  gap: 2,
                  height: "50%",
                  overflowY: "auto",
                  alignItems: "center",
                  mb: 2,
                }}
              >
                {images.slice(1, 3).map((image, index) => (
                  <CardMedia
                    key={index}
                    component="img"
                    image={`${process.env.REACT_APP_API_SER}photos/${image.id}`}
                    alt={`Image ${index}`}
                    sx={{
                      borderRadius: 3,
                      width: 336,
                      height: 224,
                      objectFit: "cover",
                    }}
                  />
                ))}
              </Box>
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: "repeat(2, 1fr)",
                  gap: 2,
                  height: "50%",
                  overflowY: "auto",
                  alignItems: "center",
                }}
              >
                {images.slice(3, 5).map((image, index) => (
                  <CardMedia
                    key={index}
                    component="img"
                    image={`${process.env.REACT_APP_API_SER}photos/${image.id}`}
                    alt={`Image ${index + 2}`}
                    sx={{
                      borderRadius: 3,
                      width: 336,
                      height: 224,
                      objectFit: "cover",
                    }}
                  />
                ))}
              </Box>
            </Box>
          </Box>
        </Card>

        <CardContent sx={{ p: 2, mb: 6 }}>
          <Grid container spacing={2} alignItems="center">
            {/* Colonne gauche - Titre */}
            <Grid item xs={12} md={8}>
              <Box sx={{ marginBottom: "-20px" }}>
                {" "}
                {/* Réduit le marginBottom en mode mobile */}
                <Typography
                  variant="h6"
                  component="div"
                  gutterBottom
                  fontWeight="500"
                  ml={{ xs: 3.5 }}
                  fontSize={{ xs: "26px", md: "30px" }} // Réduit la taille de la police en mode mobile
                  fontFamily="Poppins"
                  sx={{
                    maxWidth: "100%", // Assure que le texte ne dépasse pas en mode mobile
                    overflowWrap: "break-word",
                    wordBreak: "break-word",
                  }}
                >
                  {e.titre}
                </Typography>
              </Box>
            </Grid>

            {/* Colonne droite - Infos de l'hôte */}
            <Grid item xs={12} md={4}>
              <Box
                display="flex"
                alignItems="center"
                ml={{ xs: 2.5, md: 5 }} // Enlève la marge gauche en mode mobile
                sx={{ marginTop: { xs: "16px", md: "16px" } }} // Ajuste le marginTop
              >
                <Box display="flex" alignItems="center" ml={{ xs: 1, md: 2 }}>
                  <Avatar
                    src={`${process.env.REACT_APP_API_SER}photos/${e.host.avatar}`}
                    sx={{
                      width: { xs: 40, md: 55 },
                      height: { xs: 40, md: 55 },
                      mr: 1,
                    }} // Ajuste la taille de l'Avatar
                  />
                  <Box>
                    <Typography
                      variant="body2"
                      fontWeight="bold"
                      sx={{
                        mr: 2,
                        fontFamily: "Poppins",
                        fontSize: { xs: "14px", md: "16px" },
                      }} // Ajuste la taille de la police
                    >
                      {e.host.nom} {e.host.prenom}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{
                        mr: 2,
                        fontFamily: "Poppins",
                        fontSize: { xs: "12px", md: "14px" },
                      }} // Ajuste la taille de la police
                    >
                      {e.lieu}
                    </Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    marginRight: { xs: 0, md: "-40px" }, // Réduit la marge à droite en mode mobile
                    fontSize: { xs: "14px", md: "18px" }, // Ajuste la taille de la police
                  }}
                >
                  <Button
                    variant="contained"
                    sx={{
                      padding: { xs: "8px", md: "10px" }, // Ajuste le padding
                      marginLeft: { xs: 8, md: "70px" }, // Enlève la marge gauche en mode mobile
                      backgroundColor: "#E3B13E",
                      borderRadius: "8px",
                      color: "white",
                      textTransform: "none",
                      fontWeight: "600",
                      fontFamily: "Poppins",
                      fontSize: { xs: "12px" }, // Ajuste la taille de la police
                      width: { xs: "80%", md: "200px" }, // Ajuste la largeur
                      "&:hover": { backgroundColor: "#d9a003" },
                    }}
                    onClick={() => navigate("/hote")}
                  >
                    Voir le profil de l'hôte
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </CardContent>
      </Box>
    </Box>
  );
};

export default TajineCard;
