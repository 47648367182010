import { Button } from "@mui/base/Button";
import DrawerAppBar from "./components/DrawerAppBar";
import { Search } from "@mui/icons-material";
import SearchBy from "./components/SearchBy";
import Experiences from "./components/Experiences";
import { Box } from "@mui/material";
import Plats from "./components/Plats";
import TopHote from "./components/TopHote";
import Carousel from "./components/TestimonialCarousel";
import TestimonialCarousel from "./components/TestimonialCarousel";
import Footer from "./components/Footer";
import { Container } from "@mui/system";
import { isEdge, isFirefox, isChrome } from "react-device-detect";
import { AddReview } from "./components/ReviewModal/AddReview";

const marginBottom = isEdge
  ? { xs: 50, sm: 50, md: 4, lg: 4 } // Marges spécifiques pour Edge
  : isFirefox
  ? { xs: 30, sm: 6, md: 8 } // Marges pour Firefox
  : isChrome
  ? { xs: 26, sm: 12, md: 14 } // Marges spécifiques pour Chrome
  : { xs: 4, sm: 6, md: 8 }; // Marges par défaut

export function Home() {
  return (
    <>
    
      <Box
        sx={{
          mb: marginBottom,
        }}
      >
        <DrawerAppBar />
        </Box>
      
      <Container>
        <Box
          sx={{
            position: "relative",
            mt: { xs: -38, sm: 10, md: 12 }, // Adjust if needed
            px: { xs: 2, sm: 3, md: 4 },
          }}
        >
          <Experiences />
          <Plats />
          <AddReview />
          <TopHote />
        </Box>
        <TestimonialCarousel />
      </Container>
      <Footer />
    </>
  );
}
