import axios from "axios";
import React, { useState } from "react";
import LocationPicker from "../components/LocationPicker.js";

function HostForm() {
  const [formData, setFormData] = useState({
    nom: "",
    prenom: "",
    email: "",
    password: "",
    city: "",
    localisation: "",
    acceptedTerms: false,
  });

  const [errors, setErrors] = useState({
    nom: "",
    prenom: "",
    email: "",
    password: "",
    city: "",
    localisation: "",
    acceptedTerms: "",
  });

  const [showLocationPicker, setShowLocationPicker] = useState(false);

  const cities = [
    "Agadir",
    "Al Hoceima",
    "Azrou",
    "Beni Mellal",
    "Casablanca",
    "Chefchaouen",
    "El Jadida",
    "El Kelaa des Sraghna",
    "Errachidia",
    "Fes",
    "Khemisset",
    "Khenifra",
    "Khouribga",
    "Marrakech",
    "Mehdia",
    "Midelt",
    "Nador",
    "Ouarzazate",
    "Rabat",
    "Safi",
    "Salé",
    "Tanger",
    "Taroudant",
    "Taza",
    "Tétouan",
    "Laayoune",
    "Dakhla",
    "Tiznit",
    "Berkane",
    "Sidi Kacem",
  ];

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (validate()) {
        const response = await axios.post(
          process.env.REACT_APP_API_SER+ "auth/hostSignUp",
          formData
        );
        console.log("Response:", response.data);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleChange = (e) => {
    const { id, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [id]: type === "checkbox" ? checked : value,
    }));
  };

  const handleLocationClick = () => {
    setShowLocationPicker(true);
  };

  const handleLocationSelect = (location) => {
    setFormData((prevData) => ({
      ...prevData,
      localisation: location,
    }));
    setShowLocationPicker(false);
  };

  const validate = () => {
    let formErrors = {};
    if (!formData.nom.trim()) formErrors.nom = "Le nom est requis.";
    if (!formData.prenom.trim()) formErrors.prenom = "Le prénom est requis.";
    if (!formData.email.trim()) {
      formErrors.email = "L'email est requis.";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      formErrors.email = "L'email n'est pas valide.";
    }
    if (!formData.password) {
      formErrors.password = "Le mot de passe est requis.";
    } else if (formData.password.length < 6) {
      formErrors.password =
        "Le mot de passe doit comporter au moins 6 caractères.";
    }
    if (!formData.city.trim()) formErrors.city = "La ville est requise.";
    if (!formData.localisation.trim())
      formErrors.localisation = "La localisation est requise.";
    if (!formData.acceptedTerms) {
      formErrors.acceptedTerms = "Vous devez accepter les termes.";
    }
    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="bg-white p-6 rounded shadow-md w-full"
    >
      <div className="flex flex-col md:grid md:grid-cols-2 gap-6">
        <div>
          <input
            type="text"
            id="nom"
            placeholder="Nom*"
            value={formData.nom}
            onChange={handleChange}
            className={`mt-1 block w-full px-5 py-5 border ${
              errors.nom ? "border-red-500" : "border-gray-input"
            } rounded-md shadow-sm focus:outline-none focus:ring-orange-text focus:border-orange-text poppins-regular text-lg`}
          />
          {errors.nom && <p className="text-red-500 text-sm">{errors.nom}</p>}
        </div>
        <div>
          <input
            type="text"
            id="prenom"
            placeholder="Prénom*"
            value={formData.prenom}
            onChange={handleChange}
            className={`mt-1 block w-full px-5 py-5 border ${
              errors.prenom ? "border-red-500" : "border-gray-input"
            } rounded-md shadow-sm focus:outline-none focus:ring-orange-text focus:border-orange-text poppins-regular text-lg`}
          />
          {errors.prenom && (
            <p className="text-red-500 text-sm">{errors.prenom}</p>
          )}
        </div>
        <div>
          <input
            type="email"
            id="email"
            placeholder="Email*"
            value={formData.email}
            onChange={handleChange}
            className={`mt-1 block w-full px-5 py-5 border ${
              errors.email ? "border-red-500" : "border-gray-input"
            } rounded-md shadow-sm focus:outline-none focus:ring-orange-text focus:border-orange-text poppins-regular text-lg`}
          />
          {errors.email && (
            <p className="text-red-500 text-sm">{errors.email}</p>
          )}
        </div>
        <div>
          <input
            type="password"
            id="password"
            placeholder="Mot de passe*"
            value={formData.password}
            onChange={handleChange}
            className={`mt-1 block w-full px-5 py-5 border ${
              errors.password ? "border-red-500" : "border-gray-input"
            } rounded-md shadow-sm focus:outline-none focus:ring-orange-text focus:border-orange-text poppins-regular text-lg`}
          />
          {errors.password && (
            <p className="text-red-500 text-sm">{errors.password}</p>
          )}
        </div>
        <div>
          <select
            id="city"
            value={formData.city}
            onChange={handleChange}
            className={`mt-1 block w-full px-5 py-5 border ${
              errors.city ? "border-red-500" : "border-gray-input"
            } rounded-md shadow-sm focus:outline-none focus:ring-orange-text focus:border-orange-text poppins-regular text-lg`}
          >
            <option value="" disabled>
              Ville*
            </option>
            {cities.map((city, index) => (
              <option key={index} value={city}>
                {city}
              </option>
            ))}
          </select>
          {errors.city && <p className="text-red-500 text-sm">{errors.city}</p>}
        </div>
        <div className="relative">
          <img
            src="https://cdn-icons-png.flaticon.com/512/2928/2928989.png"
            alt="Localisation Icon"
            className="absolute left-3 top-1/2 transform -translate-y-1/2 w-6 h-6 pointer-events-none"
          />
          <input
            type="text"
            id="localisation"
            placeholder="Localisation*"
            value={formData.localisation}
            onClick={handleLocationClick}
            readOnly
            className={`mt-1 block w-full pl-12 py-5 border ${
              errors.localisation ? "border-red-500" : "border-gray-input"
            } rounded-md shadow-sm focus:outline-none focus:ring-orange-text focus:border-orange-text poppins-regular text-lg cursor-pointer`}
          />
          {errors.localisation && (
            <p className="text-red-500 text-sm">{errors.localisation}</p>
          )}
        </div>
      </div>
      <div className="flex flex-col md:flex-row justify-between">
        <div className="mt-6">
          <label className="inline-flex items-center">
            <input
              type="checkbox"
              id="acceptedTerms"
              checked={formData.acceptedTerms}
              onChange={handleChange}
              className="form-checkbox text-yellow-500 w-7 h-7 rounded-md"
            />
            <span className="ml-2 text-gray-700">
              J'accepte les{" "}
              <a href="#" className="text-gray-text poppins-medium">
                politiques et conditions d'utilisations
              </a>
            </span>
          </label>
          {errors.acceptedTerms && (
            <p className="text-red-500 text-sm">{errors.acceptedTerms}</p>
          )}
        </div>
        <div className="mt-6">
          <button
            type="submit"
            className="w-full md:w-auto bg-orange-text text-white py-2 px-4 rounded-md md:w-64 md:h-14 poppins-regular text-xl shadow-sm hover:bg-yellow-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500"
          >
            S'inscrire
          </button>
        </div>
      </div>
      {showLocationPicker && (
        <LocationPicker
          onClose={() => setShowLocationPicker(false)}
          onSelectLocation={handleLocationSelect}
        />
      )}
    </form>
  );
}

export default HostForm;
