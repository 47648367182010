import React from 'react';
import { Modal, Box, Typography, Button } from '@mui/material';
import { format } from 'date-fns';
import axios from 'axios';

const ReservationModal = ({ open, onClose, reservation }) => {
  if (!reservation) return null;

  const startDate = new Date(reservation.reservationDate);
  const formattedStartDate = format(startDate, 'yyyy-MM-dd HH:mm:ss');
  
  const durationInMinutes = parseInt(reservation.experienceCulinaire.duree.split(' ')[0], 10);
  const endDate = new Date(startDate.getTime() + durationInMinutes * 60000);
  const formattedEndDate = format(endDate, 'yyyy-MM-dd HH:mm:ss');

  const handleReject = async () => {
    try {
      await axios.put(`${process.env.REACT_APP_API_URL}/reservations/reject/${reservation.id}`);
      onClose(); // Close the modal after rejecting
      // Optionally, refresh the calendar or show a success message
    } catch (error) {
      console.error('Error rejecting reservation:', error);
      // Optionally, show an error message
    }
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="reservation-modal-title"
      aria-describedby="reservation-modal-description"
    >
      <Box sx={{ 
        position: 'absolute', 
        top: '50%', 
        left: '50%', 
        transform: 'translate(-50%, -50%)', 
        width: '80%', 
        maxWidth: '600px', 
        bgcolor: 'background.paper', 
        borderRadius: 2, 
        boxShadow: 24, 
        p: 4,
        overflowY: 'auto',
      }}>
        <Typography id="reservation-modal-title" variant="h6" component="h2">
          Reservation Details
        </Typography>
        <Typography id="reservation-modal-description" sx={{ mt: 2 }}>
          <strong>Title:</strong> {reservation.experienceCulinaire.titre}
        </Typography>
        <Typography>
          <strong>Start Date:</strong> {formattedStartDate}
        </Typography>
        <Typography>
          <strong>End Date:</strong> {formattedEndDate}
        </Typography>
        <Typography>
          <strong>Description:</strong> {reservation.description}
        </Typography>
        <Typography>
          <strong>Client:</strong> {reservation.user?.nom}
        </Typography>
        <Box sx={{ mt: 2, display: 'flex', gap: 2 }}>
          <Button onClick={handleReject} variant="outlined" color="error">
            Reject
          </Button>
          <Button onClick={onClose} variant="outlined">
            Close
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default ReservationModal;
