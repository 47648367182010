import React, { useState, useEffect } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import axios from 'axios';
import { Container, Typography, Box, Paper, IconButton, Tooltip, Modal } from '@mui/material';
import { Event as EventIcon, Close as CloseIcon } from '@mui/icons-material';
import ReservationModal from './ReservationModal'; // Ensure this path is correct

const localizer = momentLocalizer(moment);

const eventPropGetter = (event) => {
  let backgroundColor = 'lightgray';
  if (event && event.title) {
    backgroundColor = event.title.includes('Dinner') ? '#4caf50' : '#f44336';
  }
  return {
    style: {
      backgroundColor,
      borderRadius: '5px',
      border: 'none',
      color: 'white', // Added for better text visibility
      padding: '5px', // Added padding for better visual separation
    },
  };
};

const CalendarComponent = () => {
  const [events, setEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  useEffect(() => {
    const fetchReservations = async () => {
      try {
        const response = await axios.get(process.env.REACT_APP_API_URL+ '/reservations');
        const reservations = response.data;
        const formattedEvents = reservations.map(reservation => ({
          start: new Date(reservation.reservationDate),
          end: new Date(reservation.reservationDate),
          title: reservation.user?.name ? `${reservation.user.name} - ${reservation.experienceCulinaire.titre}` : `Reservation - ${reservation.experienceCulinaire.titre}`,
          description: `Guests: ${reservation.nombreInvited} - Total Price: ${reservation.prixTotal}`,
          reservation,
        }));
        console.log('formattedEvents:', formattedEvents);
        setEvents(formattedEvents.filter(event => event.reservation.status !== 'REJECTED'));
      } catch (error) {
        console.error('Error fetching reservations:', error);
      }
    };

    fetchReservations();
  }, []);
  const handleSelectEvent = (event) => {
    setSelectedEvent(event);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setSelectedEvent(null);
  };

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Paper elevation={3} sx={{ p: 2 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="h4" component="h1">
            Reservations Calendar
          </Typography>
          <Tooltip title="Add Event">
            <IconButton color="primary" onClick={() => console.log('Add event')}>
              <EventIcon />
            </IconButton>
          </Tooltip>
        </Box>
        <Box sx={{ height: '600px', mt: 2 }}>
          <Calendar
            localizer={localizer}
            events={events}
            startAccessor="start"
            endAccessor="end"
            selectable
            onSelectEvent={handleSelectEvent}
            eventPropGetter={eventPropGetter}
            style={{ height: '100%', borderRadius: '8px', border: '1px solid #ddd' }} // Added border for distinction
          />
        </Box>
      </Paper>

      <ReservationModal
        open={modalOpen}
        onClose={handleCloseModal}
        reservation={selectedEvent?.reservation}
      />
    </Container>
  );
};

export default CalendarComponent;
