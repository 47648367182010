import React, { useState, useEffect } from "react";
import { Avatar, Box, Typography, Button, Grid } from "@mui/material";
import { UserOutlined } from "@ant-design/icons";
import RatingInfos from "./RatingInfos";
import { useParams } from "react-router-dom";

const ReviewResults = () => {
  const [reviews, setReviews] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const reviewsPerPage = 4;
  const { id } = useParams();

  useEffect(() => {
    fetchReviews();
  }, [id]);

  const fetchReviews = async () => {
    try {
      const response = await fetch(`http://localhost:8080/review/getAll/${id}`);
      const data = await response.json();
      setReviews(data);
    } catch (error) {
      console.error("Error fetching reviews:", error);
    }
  };

  const handleShowMore = () => {
    setCurrentPage((prev) => prev + 1);
  };

  const handleShowLess = () => {
    setCurrentPage((prev) => Math.max(0, prev - 1));
  };

  const startIndex = currentPage * reviewsPerPage;
  const visibleReviews = reviews.slice(startIndex, startIndex + reviewsPerPage);

  return (
    <Box
      mt={4}
      mb={4}
      sx={{
        width: { xs: "90%", sm: "80%", md: "674px" },
        height: "auto",
        backgroundColor: "#F5F5F5",
        borderRadius: "20px",
        marginLeft: { xs: "auto", md: "120px" },
        marginRight: { xs: "auto", md: "auto" },
        padding: { xs: "20px", md: "40px" },
        overflow: "visible",
      }}
    >
      <RatingInfos />
      {visibleReviews.map((review) => (
        <Box key={review.id} mb={2} mt={2} ml={{ xs: 1, md: 8 }}>
          <Grid
            container
            alignItems="center"
            spacing={1}
            mb={1}
            sx={{ ml: { xs: 0.5, md: -1.5 } }}
          >
            <Grid item>
              <Avatar
                src={review.avatar || ""}
                alt={review.userName}
                sx={{
                  backgroundColor: !review.avatar ? "#d7a022" : "transparent",
                  color: !review.avatar ? "#fff" : "transparent",
                  width: 56,
                  height: 56,
                }}
              >
                {!review.avatar && <UserOutlined />}
              </Avatar>
            </Grid>
            <Grid item xs>
              {review.userName && (
                <Typography sx={{ textAlign: "left" }}>
                  {review.userName}
                </Typography>
              )}
              <Typography sx={{ textAlign: "left", fontSize: "12px" }}>
                {review.createdAt}
              </Typography>
            </Grid>
          </Grid>
          <Box
            display="flex"
            sx={{
              maxWidth: { xs: "100%", md: "600px" },
              paddingRight: { xs: "0", md: "48px" },
            }}
          >
            <Typography
              variant="body2"
              sx={{
                textAlign: "left",
                whiteSpace: "pre-line",
                overflowWrap: "break-word",
                wordBreak: "break-word",
                marginLeft: { xs: 2, md: 0 },
              }}
            >
              {typeof review.comment === "string"
                ? review.comment
                : "Commentaire invalide"}
            </Typography>
          </Box>
        </Box>
      ))}
      <Box display="flex" justifyContent="center" mt={2}>
        {startIndex + reviewsPerPage < reviews.length && (
          <Button
            onClick={handleShowMore}
            sx={{
              color: "#d7a022",
              fontWeight: "bold",
              textDecoration: "underline",
              "&::first-letter": { textTransform: "uppercase" },
              textTransform: "none",
            }}
          >
            Voir tous les avis
          </Button>
        )}
        {currentPage > 0 && (
          <Button
            onClick={handleShowLess}
            sx={{
              marginLeft: 2,
              color: "#d7a022",
              fontWeight: "bold",
              textDecoration: "underline",
              "&::first-letter": { textTransform: "uppercase" },
              textTransform: "none",
            }}
          >
            Voir moins
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default ReviewResults;
