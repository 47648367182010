import React, { useEffect, useState } from "react";
import logoDark from "../img/Group 2040.webp";
import messageIcon from "../img/Icon.webp";
import notifIcon from "../img/Icon(1).webp";
import { useNavigate } from "react-router-dom";

function NavHote({avatar}) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [avatarImg, setAvatarImg] = useState(avatar);
  const navigate=useNavigate();
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  useEffect(() => {
    setAvatarImg(localStorage.getItem("avatar"));
  }, [avatarImg]);
  return (
    <nav className="flex items-center justify-between p-4 md:px-20 md:py-9 absolute top-0 w-full z-20 bg-white">
      {/* Logo */}
      <div className="flex items-center">
        <img
          src={logoDark}
          alt="Feast and Food Logo"
          className="h-12 md:h-14 lg:h-16"
          onClick={()=>navigate('/')}
          style={{cursor:"pointer"}}
        />
      </div>

      {/* Buttons and Profile Picture for larger screens */}
      <div className="hidden md:flex items-center space-x-4 lg:space-x-20">
        <button className="text-grey-text poppins-medium text-base md:text-lg"
        onClick={()=>navigate('/tableau-de-bord')}
        >
          Tableau de bord
        </button>
        <button className="text-grey-text poppins-medium text-base md:text-lg"
        onClick={()=>navigate('/mes-annonces')}
        >
          Mes annonces
        </button>
        <button className="text-grey-text poppins-medium text-base md:text-lg"
        onClick={()=>navigate('/reservations')}
        >
          Réservations
        </button>
        <button className="text-grey-text poppins-medium text-base md:text-lg">
          Banque d’images
        </button>
        <button onClick={()=>navigate("/HostProfile")}>
          <img
            src={avatarImg} // Replace with actual profile picture URL or component
            alt="Profile"
            className="h-8 w-8 md:w-16 md:h-16 rounded-full"
          />
        </button>
      </div>

      {/* Hamburger Icon for smaller screens */}
      <button
        data-collapse-toggle="navbar-menu"
        type="button"
        className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden "
        aria-controls="navbar-menu"
        aria-expanded={isMenuOpen}
        onClick={toggleMenu}>
        <span className="sr-only">Open main menu</span>
        <svg
          className="w-5 h-5"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 17 14">
          <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M1 1h15M1 7h15M1 13h15"
          />
        </svg>
      </button>

      {/* Dropdown Menu */}
      {isMenuOpen && (
        <div className="absolute top-full left-0 w-full bg-white shadow-md md:hidden ">
          <div className="flex flex-col items-center space-y-2 py-4">
            <button className="text-grey-text poppins-medium text-base">
              Tableau de bord
            </button>
            <button className="text-grey-text poppins-medium text-base">
              Mes annonces
            </button>
            <button className="text-grey-text poppins-medium text-base">
              Réservations
            </button>
            <button className="text-grey-text poppins-medium text-base">
              Banque d’images
            </button>
          </div>
        </div>
      )}

      {/* Icon Buttons */}
      <div className="hidden md:flex items-center md:space-x-5">
        <button className="text-white">
          <img
            src={messageIcon}
            alt="Message Icon"
            className="h-4 w-6 sm:h-5 sm:w-7 md:h-6 md:w-8"
          />
        </button>
        <button className="text-white">
          <img
            src={notifIcon}
            alt="Notification Icon"
            className="h-4 w-6 sm:h-5 sm:w-7 md:h-6 md:w-8"
          />
        </button>
      </div>
    </nav>
  );
}

export default NavHote;
