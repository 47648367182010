import React, { useEffect, useState } from "react";
import axios from "axios";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import {
  CardMedia,
  CardActionArea,
  Grid,
  Box,
  IconButton,
  Avatar,
} from "@mui/material";
import LaunchIcon from "@mui/icons-material/Launch";
import { useKeycloak } from "@react-keycloak/web";

export default function TopHote() {
  const [hoteData, setHoteData] = useState([]);
  const [city, setCity] = useState(null);
  const { keycloak } = useKeycloak();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchExperiencePhotos = async (hoteId) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_SER}api/host/${hoteId}/experience-photos`, {
        headers: {
          Authorization: `Bearer ${keycloak.token}`,
        },
      });
      return response.data.map(photoId => `https://34.67.190.42:8443/photos/${photoId}`);
    } catch (error) {
      console.error(`Erreur lors de la récupération des photos pour l'hôte ${hoteId} :`, error);
      return [];
    }
  };

  const fetchTopHosts = async () => {
    setLoading(true);
    setError(null);
    try {
      let response;

      
          response = await axios.get(process.env.REACT_APP_API_SER+"api/host/top-rated");

      setHoteData(response.data);
      console.log("Top hosts with photos:", response.data);
    } catch (error) {
      console.error("Erreur lors de la récupération des hôtes :", error);
      setError("Une erreur s'est produite lors de la récupération des hôtes.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTopHosts();
  }, [keycloak.authenticated, keycloak.token]);

  useEffect(() => {
    const onAuthSuccess = () => {
      fetchTopHosts();
    };

    if (keycloak.authenticated) {
      onAuthSuccess();
    } else {
      keycloak.onAuthSuccess = onAuthSuccess;
    }

    return () => {
      keycloak.onAuthSuccess = null;
    };
  }, [keycloak]);

  return (
    <Box sx={{ padding: "20px", textAlign: "left", marginTop: "30px" }}>

      <Typography variant="h4" sx={{ marginBottom: "40px", fontFamily: "Poppins", fontWeight: "600" }}>

        {city ? `Top hôtes à ${city}` : "Top hôtes près de chez vous"}
      </Typography>
      {loading ? (
        <Typography>Chargement...</Typography>
      ) : error ? (
        <Typography sx={{ color: "red" }}>{error}</Typography>
      ) : (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Grid container spacing={3} sx={{ maxWidth: "1600px" }}>
            {hoteData.map((hote, index) => (
              <Grid key={index} item xs={12} sm={6} md={4}>
                <Card
                  sx={{
                    boxShadow: "none",
                    padding: 0,
                    margin: "0 auto",
                    backgroundColor: "#f8f8f8",
                    height: "100%",
                  }}
                >
                  <CardActionArea sx={{ padding: 0 }}>
                    <Grid container>
                      <Grid item xs={6}>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            padding: "0 3px",
                          }}
                        >
                          <Avatar

                            src={process.env.REACT_APP_API_SER+"photos/"+ hote.avatar || "default-image.png"}  

                            alt={hote.name}
                            sx={{
                              width: 100,
                              height: 100,
                              borderRadius: "50%",
                              marginBottom: 2,
                              marginTop: 5,
                            }}
                          />
                          <Typography
                            sx={{
                              fontWeight: "bold",
                              fontSize: "12px",
                              textAlign: "center",
                            }}
                          >
                            {hote.name}
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: "10px",
                              textAlign: "center",
                              marginTop: "10px",
                              overflow: "hidden",
                              display: "-webkit-box",
                              WebkitBoxOrient: "vertical",
                              WebkitLineClamp: 4,
                              color: "#a29c9c",
                              padding: "0 3px",
                            }}
                          >
                            {hote.bio}
                          </Typography>
                          <IconButton
                            sx={{
                              marginTop: "10px",
                              backgroundColor: "transparent",
                              "&:hover": {
                                backgroundColor: "transparent",
                              },
                            }}
                            onClick={() => {
                              // Action à effectuer lors du clic sur le bouton
                            }}
                          >
                            <LaunchIcon
                              sx={{
                                fontSize: "16px",
                                color: "orange",
                                marginBottom: "15px",
                              }}
                            />
                          </IconButton>
                        </Box>
                      </Grid>
                      <Grid item xs={6}>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            height: "100%",
                          }}
                        >
                          {hote.experiencePhotos.length > 0 && (
                            <>
                              <CardMedia
                                component="img"
                                sx={{
                                  width: "100%",
                                  height: 150,
                                  objectFit: "cover",
                                  marginBottom: "10px",
                                }}
                                image={process.env.REACT_APP_API_SER+"photos/"+hote.experiencePhotos[0]}
                                alt="Image 1"
                              />
                              {hote.experiencePhotos[1] && (
                                <CardMedia
                                  component="img"
                                  sx={{
                                    width: "100%",
                                    height: 150,
                                    objectFit: "cover",
                                  }}
                                  image={process.env.REACT_APP_API_SER+"photos/"+ hote.experiencePhotos[1]}
                                  alt="Image 2"
                                />
                              )}
                            </>
                          )}
                        </Box>
                      </Grid>
                    </Grid>
                  </CardActionArea>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Box>
      )}
    </Box>
  );
}
