import "./Plat.css";

export default function Starts({ stars, reviews }) {
  return (
    <div style={{ marginTop: -20, display: "flex", marginLeft: "0px" }}>
      {stars >= 1 && (
        <label
          htmlFor="star1"
          className="star"
          style={{ color: "orange", fontSize: "24px" }}
        >
          <svg
            width="20"
            height="20"
            viewBox="0 0 15 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              id="Vector"
              d="M14.1218 7.53928C15.1487 6.76255 14.5994 5.12574 13.3118 5.12574H10.9821C10.3933 5.12574 9.87319 4.74215 9.69928 4.17963L9.00478 1.9332C8.61478 0.671731 6.8292 0.671731 6.43921 1.93321L5.7447 4.17963C5.5708 4.74215 5.0507 5.12574 4.46192 5.12574H2.09191C0.808496 5.12574 0.256556 6.75381 1.27537 7.5343L3.30521 9.08931C3.75036 9.43033 3.9367 10.0121 3.77253 10.5483L3.03501 12.957C2.65253 14.2062 4.09833 15.2105 5.1354 14.416L6.90545 13.06C7.38725 12.6909 8.05673 12.6909 8.53853 13.06L10.2929 14.404C11.3315 15.1996 12.7787 14.1915 12.3923 12.9416L11.6445 10.5229C11.4776 9.983 11.6666 9.39637 12.1173 9.05546L14.1218 7.53928Z"
              fill="#FFAD33"
            />
          </svg>
        </label>
      )}
      {stars >= 2 && (
        <label
          htmlFor="star2"
          className="star"
          style={{ color: "orange", fontSize: "24px" }}
        >
          <svg
            width="20"
            height="20"
            viewBox="0 0 15 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              id="Vector"
              d="M14.1218 7.53928C15.1487 6.76255 14.5994 5.12574 13.3118 5.12574H10.9821C10.3933 5.12574 9.87319 4.74215 9.69928 4.17963L9.00478 1.9332C8.61478 0.671731 6.8292 0.671731 6.43921 1.93321L5.7447 4.17963C5.5708 4.74215 5.0507 5.12574 4.46192 5.12574H2.09191C0.808496 5.12574 0.256556 6.75381 1.27537 7.5343L3.30521 9.08931C3.75036 9.43033 3.9367 10.0121 3.77253 10.5483L3.03501 12.957C2.65253 14.2062 4.09833 15.2105 5.1354 14.416L6.90545 13.06C7.38725 12.6909 8.05673 12.6909 8.53853 13.06L10.2929 14.404C11.3315 15.1996 12.7787 14.1915 12.3923 12.9416L11.6445 10.5229C11.4776 9.983 11.6666 9.39637 12.1173 9.05546L14.1218 7.53928Z"
              fill="#FFAD33"
            />
          </svg>
        </label>
      )}
      {stars >= 3 && (
        <label
          htmlFor="star3"
          className="star"
          style={{ color: "orange", fontSize: "24px" }}
        >
          <svg
            width="20"
            height="20"
            viewBox="0 0 15 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              id="Vector"
              d="M14.1218 7.53928C15.1487 6.76255 14.5994 5.12574 13.3118 5.12574H10.9821C10.3933 5.12574 9.87319 4.74215 9.69928 4.17963L9.00478 1.9332C8.61478 0.671731 6.8292 0.671731 6.43921 1.93321L5.7447 4.17963C5.5708 4.74215 5.0507 5.12574 4.46192 5.12574H2.09191C0.808496 5.12574 0.256556 6.75381 1.27537 7.5343L3.30521 9.08931C3.75036 9.43033 3.9367 10.0121 3.77253 10.5483L3.03501 12.957C2.65253 14.2062 4.09833 15.2105 5.1354 14.416L6.90545 13.06C7.38725 12.6909 8.05673 12.6909 8.53853 13.06L10.2929 14.404C11.3315 15.1996 12.7787 14.1915 12.3923 12.9416L11.6445 10.5229C11.4776 9.983 11.6666 9.39637 12.1173 9.05546L14.1218 7.53928Z"
              fill="#FFAD33"
            />
          </svg>
        </label>
      )}
      {stars >= 4 && (
        <label
          htmlFor="star4"
          className="star"
          style={{ color: "orange", fontSize: "24px" }}
        >
          <svg
            width="20"
            height="20"
            viewBox="0 0 15 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              id="Vector"
              d="M14.1218 7.53928C15.1487 6.76255 14.5994 5.12574 13.3118 5.12574H10.9821C10.3933 5.12574 9.87319 4.74215 9.69928 4.17963L9.00478 1.9332C8.61478 0.671731 6.8292 0.671731 6.43921 1.93321L5.7447 4.17963C5.5708 4.74215 5.0507 5.12574 4.46192 5.12574H2.09191C0.808496 5.12574 0.256556 6.75381 1.27537 7.5343L3.30521 9.08931C3.75036 9.43033 3.9367 10.0121 3.77253 10.5483L3.03501 12.957C2.65253 14.2062 4.09833 15.2105 5.1354 14.416L6.90545 13.06C7.38725 12.6909 8.05673 12.6909 8.53853 13.06L10.2929 14.404C11.3315 15.1996 12.7787 14.1915 12.3923 12.9416L11.6445 10.5229C11.4776 9.983 11.6666 9.39637 12.1173 9.05546L14.1218 7.53928Z"
              fill="#FFAD33"
            />
          </svg>
        </label>
      )}
      {stars >= 5 && (
        <label
          htmlFor="star5"
          className="star"
          style={{ color: "orange", fontSize: "24px" }}
        >
          <svg
            width="20"
            height="20"
            viewBox="0 0 15 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              id="Vector"
              d="M14.1218 7.53928C15.1487 6.76255 14.5994 5.12574 13.3118 5.12574H10.9821C10.3933 5.12574 9.87319 4.74215 9.69928 4.17963L9.00478 1.9332C8.61478 0.671731 6.8292 0.671731 6.43921 1.93321L5.7447 4.17963C5.5708 4.74215 5.0507 5.12574 4.46192 5.12574H2.09191C0.808496 5.12574 0.256556 6.75381 1.27537 7.5343L3.30521 9.08931C3.75036 9.43033 3.9367 10.0121 3.77253 10.5483L3.03501 12.957C2.65253 14.2062 4.09833 15.2105 5.1354 14.416L6.90545 13.06C7.38725 12.6909 8.05673 12.6909 8.53853 13.06L10.2929 14.404C11.3315 15.1996 12.7787 14.1915 12.3923 12.9416L11.6445 10.5229C11.4776 9.983 11.6666 9.39637 12.1173 9.05546L14.1218 7.53928Z"
              fill="#FFAD33"
            />
          </svg>
        </label>
      )}
      ({reviews})
    </div>
  );
}
