import React, { useState } from "react";
import { Input, Button } from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";
import TextArea from "antd/lib/input/TextArea";

const MenuForm = () => {
  const [fields, setFields] = useState({
    Entrées: [{ title: "", description: "" }],
    "Plats Principaux": [{ title: "", description: "" }],
    Desserts: [{ title: "", description: "" }],
  });

  const handleFieldChange = (category, index, field, value) => {
    setFields((prevFields) => ({
      ...prevFields,
      [category]: prevFields[category].map((item, i) =>
        i === index ? { ...item, [field]: value } : item
      ),
    }));
  };

  const addField = (category) => {
    setFields((prevFields) => ({
      ...prevFields,
      [category]: [...prevFields[category], { title: "", description: "" }],
    }));
  };

  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "1fr 1fr",
        gap: "20px",
        margin: "20px",
      }}
    >
      {/* Première colonne: Formulaire du menu */}
      <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "40px",
            marginBottom: "20px",
          }}
        >
          <div
            style={{
              fontSize: "16px",
              fontWeight: "bold",
              color: "#7C7C7C",
              fontFamily: "Poppins",
            }}
          >
            Menu*
          </div>
        </div>

        {Object.keys(fields).map((category) => (
          <div key={category}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "20px",
              }}
            >
              <div
                style={{
                  fontFamily: "Poppins",
                  fontSize: "14px",
                  marginBottom: "-10px",
                  color: "#7C7C7C",
                }}
              >
                {category}
              </div>
              {fields[category].map((field, index) => (
                <div key={index}>
                  <Input
                    placeholder="Titre"
                    value={field.title}
                    onChange={(e) =>
                      handleFieldChange(
                        category,
                        index,
                        "title",
                        e.target.value
                      )
                    }
                  />
                  <TextArea
                    value={field.description}
                    onChange={(e) =>
                      handleFieldChange(
                        category,
                        index,
                        "description",
                        e.target.value
                      )
                    }
                    placeholder="Description"
                    //autoSize={{ minRows: 3, maxRows: 5 }}
                    style={{ marginTop: "10px", height: "150px" }}
                  />
                </div>
              ))}
              <Button
                icon={<PlusCircleOutlined />}
                onClick={() => addField(category)}
                style={{
                  alignSelf: "center",
                  backgroundColor: "transparent",
                  border: "none",
                  boxShadow: "none",
                  marginTop: "-10px",
                }}
              />
            </div>
          </div>
        ))}
      </div>

      {/* Deuxième colonne: Aperçu */}
      <div
        style={{
          position: "relative",
          fontFamily: "Poppins",
          fontSize: "12px",
          marginBottom: "-30px",
          fontWeight: "600",
          color: "#7C7C7C",
          marginLeft: "8px",
        }}
      >
        <div
          style={{
            position: "absolute",
            top: "30px",
            left: "10%",
            transform: "translateX(-50%)",
            fontFamily: "Poppins",
            fontSize: "14px",
            fontWeight: 600,
            lineHeight: "21.64px",
            textAlign: "center",
            color: "#7C7C7C",
            padding: "5px 10px",
            borderRadius: "5px",
            zIndex: 1, // Ajouté pour s'assurer que le texte est au-dessus de l'image
          }}
        >
          Aperçu
        </div>

        <div
          style={{
            width: "100%",
            height: "700px",
            backgroundImage: `url('/imgs/designMenu.png')`,
            border: "1px solid #DBA976",
            backgroundSize: "cover",
            backgroundPosition: "center",
            borderRadius: "20px",
            position: "relative",
            color: "black",
            padding: "60px",
            fontSize: "12px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "90px",
            overflow: "hidden", // Assure que rien ne dépasse
          }}
        >
          <span
            style={{
              position: "absolute",
              top: "20px",
              left: "50%",
              transform: "translateX(-50%)",
              fontFamily: "Poppins",
              fontSize: "18px",
              fontWeight: 600,
              lineHeight: "21.64px",
              textAlign: "center",
              color: "black",
            }}
          >
            Menu
          </span>

          <div
            style={{
              marginTop: "60px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: "100%",
            }}
          >
            {Object.entries(fields).map(([category, items]) => (
              <div key={category}>
                {items.map((item, index) => (
                  <div key={index} style={{ marginBottom: "30px" }}>
                    <p
                      style={{
                        fontWeight: "bold",
                        margin: "0",
                        color: "#B88454",
                        fontSize: "13px",
                        textAlign: "center",
                      }}
                    >
                      {item.title}
                    </p>
                    <p
                      style={{
                        margin: "0",
                        fontStyle: "italic",
                        fontSize: "13px",
                        textAlign: "center",
                      }}
                    >
                      {item.description}
                    </p>
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MenuForm;
