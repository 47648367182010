import * as React from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import TestImage from "../img/testTxt.png";
import MobileImage from "../img/d1.png"; // Importez votre image mobile
import SearchBy from "./SearchBy.js";
import SearchByM from "./SearchByM.js";
import { useNavigate } from "react-router-dom";
import Nav from "../comps/Nav.jsx";
import Header from "./HeaderMobile.js";
import MobileSearchBar from "./MobileSearch.js";
import { Container } from "@mui/system";
const drawerWidth = 240;
const navItems = [
  "Expériences",
  "A propos",
  "Contact",
  "Se connecter",
  "S'inscrire",
  "Devenir hôte",
];

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
});

function DrawerAppBar(props) {
  const navigate = useNavigate();
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const isMobile = useMediaQuery(theme.breakpoints.down("md")); // Vérifie si en mode mobile

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };
  const goToHote = () => {
    navigate("/inscription");
  };
  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <Typography variant="h6" sx={{ my: 2 }}>
        <img
          src="../img/logo-removebg-preview.png"
          alt="Logo"
          style={{ height: "50px", width: "auto" }}
        />
      </Typography>

      <Divider />
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <Box sx={{  height: "80vh",marginBottom:"20px" }}>
          <Nav></Nav>
          <nav>
            <Drawer
              container={container}
              variant="temporary"
              open={mobileOpen}
              onClose={handleDrawerToggle}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
              sx={{
                "& .MuiDrawer-paper": {
                  boxSizing: "border-box",
                  maxWidth: drawerWidth,
                },
              }}
            >
              {drawer}
            </Drawer>
          </nav>
          <Box component="main">
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                overflow: "hidden",
              }}
            >
              {isMobile ? (
                <Header />
              ) : (
                <img
                  src={TestImage}
                  alt="Test"
                  style={{
                    width: "100vw",
                    maxHeight: "100vh",
                    objectFit: "cover",
                  }}
                />
              )}

              {isMobile ? (
                <Box
                  sx={{
                    position: "absolute",

                    top: 460,
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    padding: "20px",
                    zIndex: 20,
                    transform: "translateX(50%)",
                  }}
                >
                  <MobileSearchBar />
                </Box>
              ) : (
                <></>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
      {isMobile ? (
        <></>
      ) : (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            position: "absolute",
            top: "650px",
          }}
        >
          <SearchBy />
        </Box>
      )}
    </ThemeProvider>
  );
}

DrawerAppBar.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default DrawerAppBar;
