import React, { useEffect, useState } from "react";
import {
  Box,
  TextField,
  FormControl,
  InputLabel,
  NativeSelect,
  Autocomplete,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Search } from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import axios from "axios";
//import { menus } from "./data"; // Importing menus from data.js
import platimg from "../img/plat.jpg";
import Starts from "./Starts";
import { Typography, Card, CardContent } from "@mui/material";
import { Divider } from "antd";

const StyledIconButton = styled("button")(({ theme }) => ({
  backgroundColor: "orange",
  width: "50px",
  height: "50px",
  borderRadius: "50%",
  border: "none",
  cursor: "pointer",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  "&:hover": {
    backgroundColor: "darkorange",
  },
  "& svg": {
    color: "white",
    fontSize: "24px",
  },
}));

const SearchButton = ({ onSearch }) => (
  <Box display="flex" alignItems="center" justifyContent="center">
    <StyledIconButton type="button" onClick={onSearch}>
      <Search />
    </StyledIconButton>
  </Box>
);

const ColorTextFields = () => {
  const [lieu, setLieu] = useState("");
  const [localisation, setVille] = useState("");
  const [temps, setDate] = useState("");
  const [titre, setTitre] = useState("");
  const [filteredMenus, setFilteredMenus] = useState([]);
  const [menus, setMenu] = useState([]);
  const platOptions = [...new Set(menus.map((menu) => menu.titre))];
  const lieuOptions = [...new Set(menus.map((menu) => menu.lieu))];

  useEffect(() => {
    const fetchMenus = async () => {
      try {
        //use axios to fetch data from the server
        const response = await axios.get(
          process.env.REACT_APP_API_SER + "experience"
        );
        setMenu(response.data);
        console.log(response.data);
      } catch (error) {
        console.error("Error fetching menus", error);
      }
    };
    fetchMenus();
  }, []);

  const FieldWithDivider = ({ children }) => (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        width: "100%",
        "& > *:first-of-type": { flex: 1 }, // Le champ prend tout l'espace disponible
      }}
    >
      {children}
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Divider
          type="vertical"
          style={{
            height: "4px",
            backgroundColor: "#A1A1A1",
          }}
        />
      </Box>
    </Box>
  );

  const handleSearch = (e) => {
    e.preventDefault(); // Prevent form submission
    const filtered = menus.filter(
      (menu) =>
        (titre === "" ||
          menu.titre.toLowerCase().includes(titre.toLowerCase())) &&
        (localisation === "" ||
          menu.localisation
            .toLowerCase()
            .includes(localisation.toLowerCase())) &&
        (lieu === "" || menu.lieu.toLowerCase().includes(lieu.toLowerCase())) &&
        (temps === "" || menu.temps === temps)
    );
    setFilteredMenus(filtered);
  };
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Box sx={{ marginTop: "-70px", zIndex: "10" }}>
      <Box
        component="form"
        onSubmit={handleSearch}
        sx={{
          width: "100%",
          display: "flex",
          padding: isMobile ? "5px" : "10px",
          borderRadius: "50px",
          boxShadow: 3,
          backgroundColor: "#fff",
          alignItems: "center",
          flexWrap: "wrap",
          justifyContent: "flex-end", // Changed to flex-end to shift fields to the right

          marginRight: isMobile ? "5%" : "10%",
          "& > :not(style)": {
            m: isMobile ? 0.5 : 1,
            width: isMobile ? "100%" : "16ch",
            minWidth: isMobile ? "auto" : "18ch",
          },
          "& .MuiDivider-root": {
            margin: 2, // Changed to 0 to remove margin
          },
          "& .MuiTextField-root, & .MuiFormControl-root": {
            marginRight: 0, // Changed to 0
            marginLeft: 0.8, // Added to remove left margin
          },
        }}
        noValidate
        autoComplete="off"
      >
        <FieldWithDivider>
          <Autocomplete
            options={platOptions}
            value={titre}
            onChange={(event, newValue) => setTitre(newValue || "")}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Plat"
                placeholder="Rechercher un plat"
                variant="standard"
                color="warning"
                InputLabelProps={{
                  shrink: true,
                  style: {
                    fontFamily: "Poppins",
                    transform: "translate(0, -45px) scale(1)",
                    transformOrigin: "top left",
                    fontWeight: "600",
                    color: "black",
                    fontSize: isMobile ? "0.8rem" : "1rem",
                  },
                }}
                InputProps={{
                  ...params.InputProps,
                  disableUnderline: true, // Supprime la ligne en dessous du champ
                }}
                sx={{
                  "& input::placeholder": {
                    fontFamily: "Poppins",
                    fontSize: "13px",
                    color: "#7C7C7C",
                    opacity: 1, // Nécessaire pour Firefox
                  },
                }}
              />
            )}
          />
        </FieldWithDivider>

        <FieldWithDivider>
          <FormControl fullWidth>
            <InputLabel
              variant="standard"
              htmlFor="uncontrolled-native"
              shrink
              sx={{
                fontFamily: "Poppins",
                transform: "translate(0, -45px) scale(1)",
                transformOrigin: "top left",
                fontWeight: "600",
                color: "black",
              }}
            >
              Ville
            </InputLabel>
            <NativeSelect
              value={localisation}
              onChange={(e) => setVille(e.target.value)}
              sx={{
                "&:before": {
                  borderBottom: "none",
                },
                "&:after": {
                  borderBottom: "none",
                },
              }}
              inputProps={{
                style: {
                  fontFamily: "Poppins",
                  fontSize: "12px",
                  color: "#7C7C7C",
                  opacity: 1,
                },
              }}
            >
              <option
                value=""
                style={{
                  fontFamily: "Poppins",
                  fontSize: "12px",
                  color: "#7C7C7C",
                  opacity: 1,
                }}
              >
                Choisir une ville
              </option>
              <option value="Tanger">Tanger</option>
              <option value="Marrakech">Marrakech</option>
              <option value="Casablanca">Casablanca</option>
              <option value="Rabat">Rabat</option>
              <option value="Fes">Fes</option>
            </NativeSelect>
          </FormControl>
        </FieldWithDivider>
        <FieldWithDivider>
          <Autocomplete
            options={lieuOptions}
            value={lieu}
            onChange={(event, newValue) => setLieu(newValue || "")}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Lieu"
                placeholder="Où ?"
                variant="standard"
                color="warning"
                InputLabelProps={{
                  shrink: true,
                  style: {
                    fontFamily: "Poppins",
                    transform: "translate(0, -45px) scale(1)",
                    transformOrigin: "top left",
                    fontWeight: "600",
                    color: "black",
                  },
                }}
                sx={{
                  "& input::placeholder": {
                    fontFamily: "Poppins",
                    fontSize: "14px",
                    color: "#7C7C7C",
                    opacity: 1,
                  },
                  "& .MuiInput-underline:before": { borderBottom: "none" },
                  "& .MuiInput-underline:after": { borderBottom: "none" },
                  "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
                    borderBottom: "none",
                  },
                }}
              />
            )}
          />
        </FieldWithDivider>
        <TextField
          label="Date"
          placeholder="Quand ?"
          type="date"
          variant="standard"
          color="warning"
          focused
          value={temps}
          onChange={(e) => setDate(e.target.value)}
          InputLabelProps={{
            style: {
              fontFamily: "Poppins",
              transform: "translate(0, -45px) scale(1)",
              transformOrigin: "top left",
              fontWeight: "600",
              color: "black",
            },
          }}
          sx={{
            "& .MuiInputBase-input": {
              fontFamily: "Poppins",
              fontSize: "14px",
              color: "#7C7C7C",
            },
            "& .MuiInputBase-input::placeholder": {
              fontFamily: "Poppins",
              fontSize: "12px",
              color: "#7C7C7C",
              opacity: 1,
            },
            "& .MuiInput-underline:before": { borderBottom: "none" },
            "& .MuiInput-underline:after": { borderBottom: "none" },
            "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
              borderBottom: "none",
            },
          }}
        />

        <Box sx={{ display: "flex", justifyContent: "flex-end", flexGrow: 1 }}>
          <SearchButton onSearch={handleSearch} />
        </Box>
      </Box>
      {filteredMenus.length > 0 && (
        <Box sx={{ marginTop: 4, maxWidth: "1000px", margin: "2rem auto" }}>
          <h2
            style={{
              textAlign: "center",
              color: "#ff5722",
              marginBottom: "1rem",
            }}
          >
            Search Results
          </h2>
          <Swiper
            modules={[Navigation, Pagination]}
            spaceBetween={30}
            slidesPerView={1}
            navigation
            pagination={{ clickable: true }}
            breakpoints={{
              640: {
                slidesPerView: 2,
              },
              768: {
                slidesPerView: 3,
              },
            }}
          >
            {filteredMenus.map((menu, index) => (
              <SwiperSlide key={index}>
                <Box
                  sx={{
                    borderRadius: 2,
                    boxShadow: 3,
                    overflow: "hidden",
                    textAlign: "center",
                    backgroundColor: "#fff",
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    boxShadow: "none",
                  }}
                >
                  <img
                    src={
                      process.env.REACT_APP_API_SER +
                      "photos/" +
                      menu.photos[0].id
                    }
                    style={{
                      width: "100%",
                      height: "200px",
                      objectFit: "cover",
                    }}
                  />
                  <Box
                    sx={{
                      padding: 2,
                      flexGrow: 1,
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      boxShadow: "none",
                    }}
                  >
                    <h3 style={{ margin: "0 0 0.5rem" }}>{menu.plat}</h3>
                    <Card style={{ boxShadow: "none" }}>
                      <CardContent
                        sx={{ fontFamily: "Poppins", boxShadow: "none" }}
                      >
                        {menu.description}
                        <div style={{ marginBottom: "9px" }} className="prices">
                          <Typography
                            style={{ fontFamily: "Poppins" }}
                            color="orange"
                          >
                            {menu.prixParInvite} MAD
                          </Typography>
                          <Typography
                            style={{
                              fontFamily: "Poppins",
                              color: "black",
                              opacity: "0.5",
                            }}
                          >
                            {menu.prixParInvite + 50} MAD
                          </Typography>
                        </div>
                      </CardContent>
                      <Starts
                        stars={
                          menu.rating === 0 ? menu.rating + 5 : menu.rating
                        }
                        reviews={menu.nombreAvis}
                      />
                    </Card>
                  </Box>
                </Box>
              </SwiperSlide>
            ))}
          </Swiper>
        </Box>
      )}
    </Box>
  );
};

export default ColorTextFields;
