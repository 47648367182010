import "./App.css";
import { ReactKeycloakProvider, useKeycloak } from "@react-keycloak/web";
import { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import HomePage from "./homePage";
import PrivateRoute from "./PrivateRoute";
import PrivateComponent from "./PrivateComponent";
import keycloak from "./keycloak";
import InscriptionHost from "./InscriptionHost";

import { Home } from "./Home";
import CalendarHote from "./components/hote/CalendarHote";
import Dashboard from "./components/hote/Dashboard";
import ProfileHost from "./Host/ProfileHost";
import Experience from "./components/experience/Experience";
import NewAnnonce from "./components/experience/NewAnnonce";
import ReviewResults from "./components/Annonce/Review";
import RatingInfos from "./components/Annonce/RatingInfos";
import { Annonce } from "./components/Annonce/Annonce";
import ReservationPopup from "./components/Annonce/Popup";
import PhotoUpload from "./components/AvatarUpload";
import Plats from "./components/Plats";
function App() {
  // const { keycloak, initialized } = useKeycloak();

  // useEffect(() => {
  //   if (keycloak.authenticated) {
  //     const refreshTokenInterval = setInterval(() => {
  //       keycloak.updateToken(70).catch(() => {
  //         keycloak.logout();
  //       });
  //     }, 60000);

  //     return () => clearInterval(refreshTokenInterval);
  //   }
  // }, [keycloak]);

  return (
    <ReactKeycloakProvider
      authClient={keycloak}
      initOptions={{
        onLoad: "check-sso",
        silentCheckSsoRedirectUri:
          window.location.origin + "/silent-check-sso.html",
      }}
    >
      <BrowserRouter>
        <Routes>
          <Route
            path="/tableau-de-bord"
            element={<CalendarHote></CalendarHote>}
          ></Route>
          <Route path="/reservations" element={<Dashboard></Dashboard>}></Route>
          <Route path="/" element={<Home></Home>}></Route>
          <Route path="/devenirhote" element={<HomePage></HomePage>} />
          <Route path="/averageRating" element={<RatingInfos></RatingInfos>} />
          <Route path="/getAll" element={<ReviewResults></ReviewResults>} />
          <Route path="/annonce" element={<Annonce></Annonce>} />
          <Route
            path="/reservation"
            element={<ReservationPopup></ReservationPopup>}
          />
          <Route
            path="/inscription"
            element=<InscriptionHost></InscriptionHost>
          />
          <Route
            path="/reservations"
            element={
              <PrivateRoute roles={["Host"]}>
                <Dashboard></Dashboard>
              </PrivateRoute>
            }
          />
          <Route
            path="/private"
            element={
              <PrivateRoute roles={["Host"]}>
                <PrivateComponent></PrivateComponent>
              </PrivateRoute>
            }
          ></Route>

          <Route
            path="/HostProfile"
            element={
              <PrivateRoute roles={["Host"]}>
                <ProfileHost></ProfileHost>
              </PrivateRoute>
            }
          ></Route>
          <Route
            path="/mes-annonces"
            element={
              <PrivateRoute roles={["Host"]}>
                <Experience> </Experience>
              </PrivateRoute>
            }
          ></Route>
          <Route
            path="/newannonce"
            element={
              <PrivateRoute roles={["Host"]}>
                <NewAnnonce></NewAnnonce>
              </PrivateRoute>
            }
          ></Route>

          <Route
            path="/addUserProfile"
            element={
              <PrivateRoute roles={["Client"]}>
                <PhotoUpload></PhotoUpload>
              </PrivateRoute>
            }></Route>
            <Route 
            path="/plats"
            element={<Plats></Plats>}
            ></Route>
        </Routes>
      </BrowserRouter>
    </ReactKeycloakProvider>

    // <Routes>
    //   <Route path="/" element={<HomePage></HomePage>} />
    //   <Route
    //     path="/private"
    //     element={
    //       <PrivateRoute roles={["admin"]}>
    //         <PrivateComponent></PrivateComponent>
    //       </PrivateRoute>
    //     }></Route>
    // </Routes>

    // <Routes>
    //   <Route path="/" exact element={HomePage} />
    //   <PrivateRoute path="/private" element={PrivateComponent} />
    //   {/* <PrivateRoute
    //       path="/admin"
    //       component={AdminComponent}
    //       roles={["admin"]}
    //     /> */}
    // </Routes>
  );
}

export default App;
