import React from 'react';
import CalendarComponent from './CalendarComponent';
import Navbar from './Navbar';
import NavHote from '../../Host/NavHote';
function  CalendarHote() {
  return (
    <>
    <NavHote />
    <div className="App" style={{marginTop:"200px"}}>
      
      <CalendarComponent />
    </div>
    </>
  );
}

export default CalendarHote;
