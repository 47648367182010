import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./ClientTestimonial.css";
import face1 from "../img/11zon_cropped.png";
import face2 from "../img/11zon_cropped (1).png";
import face3 from "../img/11zon_cropped (2).png";
import { Typography } from "@mui/material";

function PrevArrow(props) {
  const { className, onClick } = props;
  return (
    <div
      className={className}
      style={{
        display: "block",
        cursor: "pointer",
        position: "absolute",
        top: "50%",
        left: "10px",
        transform: "translateY(-50%)",
        zIndex: 2,
      }}
      onClick={onClick}
    >
      <svg
        width="54"
        height="53"
        viewBox="0 0 54 53"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <ellipse
          cx="26.6398"
          cy="26.2285"
          rx="26.6398"
          ry="26.2285"
          transform="matrix(-1 0 0 1 53.2795 0)"
          fill="#7C7C7C"
        />
        <path
          d="M30.6657 19.4989L23.1719 26.9928L30.6657 34.4866"
          stroke="white"
          strokeWidth="2.99754"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
}

function NextArrow(props) {
  const { className, onClick } = props;
  return (
    <div
      className={className}
      style={{
        display: "block",
        cursor: "pointer",
        position: "absolute",
        top: "50%",
        right: "10px",
        transform: "translateY(-50%)",
        zIndex: 2,
      }}
      onClick={onClick}
    >
      <svg
        width="54"
        height="53"
        viewBox="0 0 54 53"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <ellipse
          cx="27.3602"
          cy="26.2285"
          rx="26.6398"
          ry="26.2285"
          fill="#7C7C7C"
        />
        <path
          d="M23.3343 19.4989L30.8281 26.9928L23.3343 34.4866"
          stroke="white"
          strokeWidth="2.99754"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
}
const ClientTestimonials = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
  };

  const testimonials = [
    {
      name: "Lina",
      location: "Fes",
      comment:
        "Mon expérience culinaire à Fès a été une véritable révélation. J'ai eu la chance de découvrir des saveurs uniques et des traditions culinaires ancestrales, transmises de génération en génération. Chaque plat était une ode à la richesse de notre patrimoine gastronomique, et j'ai été profondément touchée par l'accueil chaleureux et l'hospitalité de la communauté locale. Cette expérience a nourri mon amour pour la cuisine et a renforcé mon désir de partager ces trésors culinaires avec d'autres passionnés de gastronomie.",
      image: face1,
    },
    {
      name: "Samira",
      location: "Casa",
      comment:
        "Participer au cours de cuisine de cornes de gazelle chez Mima à Rabat a été une expérience inoubliable. Sous sa direction experte, j'ai appris les secrets de la pâte feuilletée fine et la technique précise pour former ces délicieux petits gâteaux en forme de croissant. L'atmosphère était conviviale et chaleureuse, et j'ai vraiment apprécié d'apprendre tout en partageant des moments de complicité avec Mima et les autres participants. Et bien sûr, déguster les cornes de gazelle fraîchement préparées à la fin du cours a été le point culminant de cette expérience culinaire mémorable.",
      image: face2,
    },
    {
      name: "Kamal",
      location: "Essaouira",
      comment:
        "Embarquer pour un food tour dans les ruelles animées de Casa aux côtés de Rachid a été une expérience sensorielle inoubliable. À chaque coin de rue, il dévoilait des joyaux culinaires méconnus, comme cette petite échoppe familiale où nous avons dégusté des sardines grillées fraîchement pêchées, accompagnées d'une sauce secrète transmise de génération en génération. Plus tard, dans un café traditionnel au charme suranné, Rachid nous a initiés au rituel du thé à la menthe, nous contant les histoires fascinantes qui se cachent derrière chaque infusion. Chaque bouchée, chaque gorgée était une invitation à découvrir l'essence même de Casa, à travers les yeux et les papilles éveillées de Rachid, véritable trésor vivant de la ville.",
      image: face3,
    },
  ];

  return (
    <div style={{ position: "relative", marginTop: "40px" }}>
      <Typography
        variant="h4"
        sx={{
          fontFamily: "Poppins",
          fontSize: "26px",
          fontWeight: "500",
          marginBottom: "50px",
        }}
      >
        What Our Clients Say About Us
      </Typography>
      <br />
      <Slider {...settings}>
        {testimonials.map((testimonial, index) => (
          <div key={index} className="testimonials-container">
            
            <div className="cards-stack">
              <div className="card card-back"></div>
              <div className="card card-middle"></div>
              <div className="card card-front">
              <div className="image-container">
              <img
                src={testimonial.image}
                alt={testimonial.name}
                className="client-image"
              />
            </div>
                <div className="card-content">
                  <div className="testimonial-content">
                    <span className="name">{testimonial.name}</span>
                    <span className="location" style={{ color: "#757575" }}>
                      {testimonial.location}
                    </span>
                    <p className="comment">{testimonial.comment}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default ClientTestimonials;
