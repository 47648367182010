import Footer from "../Footer";
import ExperienceCard from "./ExperienceCard";
import MenuSection from "./MenuITem";
import Nav from "./Nav";
import ReservationCard from "./ReservationCard";
import ReviewResults from "./Review";
import styled from "styled-components";
import TajineCard from "./TajineCard";
import HostMapWithRadius from "./HostMapWithRadius";
import React, { useState, useEffect } from "react";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 80px); // Ajout de la hauteur du footer
  @media (max-width: 768px) {
    overflow-x: hidden;
  }
`;

const FullWidthSection = styled.div`
  width: 100%;
  margin-top: 200px;
  display: flex;
  justify-content: center;
  @media (max-width: 768px) {
    margin-top: 120px;
    margin-left: -10px;
  }
`;

const ContentWrapper = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-gap: 2rem;
  margin-top: 1rem;

  flex: 1;
  @media (max-width: 768px) {
    grid-template-columns: 1fr; // Passer à une colonne en mode mobile
    justify-items: center; // Centrer les éléments sur mobile
  }
`;

const LeftColumn = styled.div`
  display: flex;
  flex-direction: column;
  @media (max-width: 768px) {
    width: 90%; // Réduire la largeur sur mobile
    align-items: center; // Centrer les éléments à l'intérieur
  }
`;

const RightColumn = styled.div`
  position: sticky;
  top: 0.2rem;
  align-self: start;
  margin-left: -40px;
  bottom: 200px;
  @media (max-width: 768px) {
    margin-left: 0;
    width: 90%; // Réduire la largeur sur mobile
    align-self: center; // Centrer la carte de réservation
  }
`;

const ExperienceCardWrapper = styled.div`
  margin-top: -14px;
  @media (max-width: 768px) {
    width: 120%;
    margin-top: -40px;
    align-self: left;
    margin-left: -170px;
  }
`;

const FooterWrapper = styled.div`
  width: 100%;
  margin-top: 80px;
  @media (max-width: 768px) {
    margin-top: 50px; // Réduire la marge sur mobile
    text-align: center; // Centrer le texte du footer sur mobile
  }
`;
const ReviewResultsWrapper = styled.div`
  @media (max-width: 768px) {
    width: 100%;
    align-self: left;
    margin-left: -70px;
    margin-top: 20px;
    width: 100%;
  }
`;
const HostMapWithRadiusWrapper = styled.div`
  @media (max-width: 768px) {
    width: 140%;
    align-self: center;
  }
`;
const MenuSectionWrapper = styled.div`
  @media (max-width: 768px) {
    width: 110%;

    margin-left: -218px;
    margin-top: 20px;
  }
`;

export function Annonce() {
  const [host, setHost] = useState({});
  const [experience, setExperience] = useState(null);
  const location = useLocation();
  const e = location.state;
  const navigate = useNavigate();
  // Redirect to the home page or any other page if `e` is null
  useEffect(() => {
    if (!e) {
      navigate("/"); // Redirect to home or any other route
    }
  }, [e, navigate]);
  console.log(e);
  useEffect(() => {
    axios
      .get(process.env.REACT_APP_API_SER + "api/host/" + e.host.id)
      .then((response) => {
        setHost(response.data);
        console.log(response.data);
      });
  }, []);
  const fields = {
    Category1: [
      { title: "Item 1", description: "Description 1" },
      { title: "Item 2", description: "Description 2" },
    ],
    Category2: [{ title: "Item 3", description: "Description 3" }],
  };

  return (
    <PageWrapper>
      <Nav color="light" />
      <FullWidthSection>
        <TajineCard e={e} />
      </FullWidthSection>
      <ContentWrapper>
        <LeftColumn>
          <ExperienceCardWrapper>
            <ExperienceCard e={e} />
          </ExperienceCardWrapper>
          <MenuSectionWrapper>
            <MenuSection fields={fields} />
          </MenuSectionWrapper>
          <ReviewResultsWrapper>
            <ReviewResults />
          </ReviewResultsWrapper>
          <HostMapWithRadiusWrapper>
            <HostMapWithRadius id={e.id} />
          </HostMapWithRadiusWrapper>
        </LeftColumn>
        <RightColumn>
          <ReservationCard e={e} />{" "}
          {/* Affiche le ReservationCard avec toute sa hauteur */}
        </RightColumn>
      </ContentWrapper>
      <FooterWrapper>
        <Footer />
      </FooterWrapper>
    </PageWrapper>
  );
}