import { useKeycloak } from "@react-keycloak/web";
import React from "react";
import Nav from "./comps/Nav";
import { useNavigate } from "react-router-dom";
function HomePage() {
  const { keycloak } = useKeycloak();
  const navigate = useNavigate();
  const handleLogin = () => {
    keycloak.login();
  };

  return (
    <div className="">
      <h1 className="text-2xl font-bold text-orange-400 ">
      </h1>
      {!keycloak.authenticated ? (
        <button onClick={handleLogin}>Login</button>
      ) : (
        <div>
          <div>
            <p>Welcome, {keycloak.tokenParsed.name}</p>
            <div>
              {
            navigate("/hostprofile")
             }

              <p>
                Token Expiry:{" "}
                {new Date(keycloak.tokenParsed.exp * 1000).toLocaleString()}
              </p>
              {/* Add more details as needed */}
            </div>
          </div>
          <p>Welcome, {keycloak.tokenParsed.preferred_username}!</p>
          <button onClick={() => keycloak.logout()}>Logout</button>
        </div>
      )}
    </div>
  );
}

export default HomePage;
